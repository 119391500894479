import client from '@framework/http'
import mock from '@/store/api/utils/mock'

const shouldMock = mock.shouldMock('logs')

/**
 * Client Logs API endpoints
 */
export default {
    async searchLogs (data) {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.get('/logs', {params: data})

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
