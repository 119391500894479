// Framework Modules
import confirm from '@framework/store/modules/confirm'
import errors from '@framework/store/modules/errors'

// Store Modules
import app from './app'
import auth from './auth'
import logs from './logs'
import mfa from './mfa'

/**
 * Register modules
 */
export default {
    app,
    auth,
    logs,
    mfa,
    errors: errors(),
    confirm: confirm()
}
