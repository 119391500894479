import baseAppModule from '@framework/store/modules/app'
import {APP_FOOTER, APP_TITLE} from '@/store/static/base-app'

const baseApp = baseAppModule()

const initialState = () => ({
    ...baseApp.state,
    title: APP_TITLE,
    footer: APP_FOOTER
})

const state = initialState()

const getters = {
    ...baseApp.getters,
    navigation: (state, getters, rootState, rootGetters) => {
        const routeNames = rootGetters['app/routeNames']

        return [
            {
                icon: 'mdi-key',
                title: 'Security',
                to: {name: routeNames.PASSWORD},
                meta: {subtitle: 'Security'},
                exact: false,
                children: [
                    {
                        icon: 'mdi-lock-outline',
                        title: 'Password',
                        exact: true,
                        to: {name: routeNames.PASSWORD}
                    },
                    {
                        icon: 'mdi-lock-plus-outline',
                        title: 'Two-Factor Authentication',
                        exact: true,
                        to: {name: routeNames.MULTI_FACTOR_AUTH}
                    },
                    {
                        icon: 'mdi-history',
                        title: 'Logs',
                        exact: false,
                        to: {name: routeNames.LOGS}
                    }
                ]
            }
        ]
    },
    title: state => state.title,
    footer: state => state.footer
}

export default {
    ...baseApp,
    getters,
    namespaced: true,
    state
}
