import 'core-js/stable'
import App from './App.vue'
import BaseRenderErrorMixin from '@core/mixins/core/base-render-error.mixin'
import router from './router'
import routeNames from './router/route-names'
import store from './store'
import SentryPlugin from '@core/plugins/sentry'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import vuetify from './plugins/vuetify'
import VueTheMask from 'vue-the-mask'

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_ENV !== 'production'
Vue.mixin(BaseRenderErrorMixin)
Vue.use(VueMeta)
Vue.use(VueTheMask)

const sentry = process.env.VUE_APP_SENTRY_URL

if (sentry) {
    Vue.use(SentryPlugin(sentry))
}

const VueInstance = new Vue({
    router,
    sentry,
    store,
    vuetify,
    render: h => h(App)
})

// Store the namespace and route names in the store for use in components
VueInstance.$store.dispatch('app/setRouteNames', routeNames)

// Mount App
VueInstance.$mount('#app')
