import client from '@framework/http'
import mock from '@/store/api/utils/mock'

const shouldMock = mock.shouldMock('auth')

/**
 * Client Auth API endpoints
 */
export default {
    async login (data) {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.post('/auth/login', data)

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async logout () {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.get('/auth/logout')

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async me (token) {
        try {
            const headers = token
                ? {'X-AUTH-TOKEN': token}
                : null

            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.get('/auth/me', {headers})

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async ping () {
        try {
            return shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.get('/ping')
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{password: string, confirm_password: string}} data
     * @returns {{}}
     */
    async changePassword (data) {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.post('/auth/change-password', data)

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
