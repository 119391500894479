import {map} from 'underscore'
import * as mutationTypes from './mutation-types'
import api from '@/store/api'

const namespaced = true

const initialState = () => ({
    logs: {
        data: [],
        pagination: {},
        filters: {
            types: [],
            clients: []
        }
    }
})

const state = initialState()

const getters = {
    logs: state => state.logs.data,
    logsFormatted: (state, getters) => map(getters.logs, (log) => ({
        client: log.client && log.client.name,
        log: log.text,
        created_at: log.created_at
    })),
    pagination: state => state.logs.pagination,
    clients: state => map(state.logs.filters.clients, (client) => ({
        text: client.name,
        value: client.uuid
    })),
    logTypes: state => map(state.logs.filters.types, (logType) => ({
        text: logType.name,
        value: logType.id
    }))
}

const actions = {
    searchLogs: async ({commit, dispatch}, data) => {
        try {
            const response = await api.logs.searchLogs(data)
            commit(mutationTypes.SET_LOGS, response)
            return response
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    resetLogs: async ({commit}) => commit(mutationTypes.RESET_LOGS)
}

const mutations = {
    [mutationTypes.RESET_LOGS]: state => Object.assign(state, initialState()),
    [mutationTypes.SET_LOGS]: (state, data) => state.logs = data
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
