import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import {ALERT_THEME, DEFAULT_THEME} from '@core/config/theme'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                ...ALERT_THEME,
                ...DEFAULT_THEME
            }
        }
    }
})
