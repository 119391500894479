import client from '@framework/http'
import mock from '@/store/api/utils/mock'

const shouldMock = mock.shouldMock('logs')

/**
 * Client MFA API endpoints
 */
export default {
    async setupMfa () {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.post('/auth/mfa')

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{code: string}} data
     * @returns {Promise<any>}
     */
    async confirmSetupMfa (data) {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.post('/auth/mfa/confirm', data)

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{code: string}} data
     * @returns {Promise<any>}
     */
    async removeMfa (data) {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.delete('/auth/mfa', {data})

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async sendEmailCode () {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.post('/auth/mfa/fallback/email/send')

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async sendSmsCode () {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.post('/auth/mfa/fallback/sms/send')

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{code: string}} data
     * @returns {Promise<any>}
     */
    async removeSmsFallback (data) {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.delete('/auth/mfa/fallback/sms', {data})

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{number: string}} data
     * @returns {Promise<any>}
     */
    async setupSmsFallback (data) {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.post('/auth/mfa/fallback/sms', data)

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{code: string}} data
     * @returns {Promise<any>}
     */
    async confirmSetupSmsFallback (data) {
        try {
            const response = shouldMock
                ? await mock.handleMock(() => ({}))
                : await client.post('/auth/mfa/fallback/sms/confirm', data)

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
