import * as mutationTypes from './mutation-types'
import api from '@/store/api'

const namespaced = true

const initialState = () => ({
    mfaSetup: {
        data: {
            uri: '',
            key: ''
        }
    },
    mfaRecoveryKey: ''
})

const state = initialState()

const getters = {
    mfaSetup: state => state.mfaSetup,
    mfaSetupUri: state => state.mfaSetup.data.uri,
    mfaSetupKey: state => state.mfaSetup.data.key,
    mfaRecoveryKey: state => state.mfaRecoveryKey
}

const actions = {
    setupMfa: async ({commit, dispatch}) => {
        try {
            const response = await api.mfa.setupMfa()
            commit(mutationTypes.SET_MFA_SETUP, response)
            return response
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    confirmSetupMfa: async ({commit, dispatch}, data) => {
        try {
            const response = await api.mfa.confirmSetupMfa(data)
            commit(mutationTypes.SET_MFA_SETUP_RECOVERY_KEY, response.recovery_key)
            return response
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    removeMfa: async ({dispatch}, data) => {
        try {
            return await api.mfa.removeMfa(data)
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    sendEmailCode: async ({dispatch}) => {
        try {
            return await api.mfa.sendEmailCode()
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    sendSmsCode: async ({dispatch}) => {
        try {
            return await api.mfa.sendSmsCode()
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    removeSmsFallback: async ({dispatch}, data) => {
        try {
            return await api.mfa.removeSmsFallback(data)
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    setupSmsFallback: async ({dispatch}, data) => {
        try {
            return await api.mfa.setupSmsFallback(data)
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    confirmSetupSmsFallback: async ({dispatch}, data) => {
        try {
            return await api.mfa.confirmSetupSmsFallback(data)
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    resetMfa: async ({commit}) => commit(mutationTypes.RESET_MFA),
    resetMfaSetupRecoveryKey: async ({commit}) => commit(mutationTypes.RESET_MFA_SETUP_RECOVERY_KEY)
}

const mutations = {
    [mutationTypes.RESET_MFA]: state => Object.assign(state, initialState()),
    [mutationTypes.SET_MFA_SETUP]: (state, data) => state.mfaSetup = data,
    [mutationTypes.SET_MFA_SETUP_RECOVERY_KEY]: (state, data) => state.mfaRecoveryKey = data,
    [mutationTypes.RESET_MFA_SETUP_RECOVERY_KEY]: state => state.mfaRecoveryKey = ''
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
