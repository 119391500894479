<template>
    <!-- App.vue -->
    <v-app>
        <!-- Navigation drawer component-->
        <base-app-navigation v-if="isAuthed" :drawer-width="370" :items="navigation"></base-app-navigation>

        <!--  Application bar component-->
        <base-app-bar v-if="isAuthed" :title="emailAddress">
            <v-spacer></v-spacer>
            <account-menu
                :email-address="emailAddress"
                :help-url="windsorUrls.CONTACT_US_URL"
                :status-page-url="windsorUrls.STATUS_PAGE_URL"
                @click:logout="handleLogout"
            ></account-menu>
        </base-app-bar>

        <!-- Confirm Dialog -->
        <base-confirm-dialog
            v-bind="confirmProps"
            @close="closeConfirm"
            @submit="submitConfirm"
        ></base-confirm-dialog>

        <!-- Content container -->
        <v-main>
            <v-container :class="{'fill-height': !isAuthed}" fluid>
                <router-view></router-view>
            </v-container>
        </v-main>

        <!-- Application footer -->
        <base-app-footer v-if="isAuthed" id="footer" :title="footer" :version="version"></base-app-footer>
    </v-app>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {LOGOUT} from './router/route-names'
    import {contains} from 'underscore'
    import windsorUrls from '@framework/store/static/windsor-urls'
    // Components
    import AccountMenu from '@/components/AccountMenu'
    import BaseAppBar from '@framework/components/BaseAppBar'
    import BaseAppFooter from '@framework/components/BaseAppFooter'
    import BaseAppNavigation from '@framework/components/BaseAppNavigation'
    import BaseConfirmDialog from '@framework/components/BaseConfirmDialog'

    export default {
        name: 'App',
        metaInfo: {
            title: 'Loading My Account',
            titleTemplate: '%s | My Account'
        },
        data: () => ({pingIntervalId: false, version: process.env.VERSION, windsorUrls}),
        components: {
            AccountMenu,
            BaseAppBar,
            BaseAppFooter,
            BaseAppNavigation,
            BaseConfirmDialog
        },
        computed: {
            ...mapGetters('app', ['navigation', 'footer']),
            ...mapGetters('auth', ['emailAddress', 'isAuthed', 'fromRoute', 'nextRoute']),
            ...mapGetters('confirm', {confirmProps: 'props'})
        },
        methods: {
            ...mapActions('auth', ['ping', 'me']),
            ...mapActions('confirm', ['closeConfirm', 'submitConfirm']),
            handleLogout () {
                this.$router.push({name: LOGOUT})
            }
        },
        watch: {
            isAuthed (newVal, oldVal) {
                const vm = this

                if ((newVal && !oldVal) && !vm.pingIntervalId) {
                    vm.pingIntervalId = setInterval(async () => {
                        try {
                            await vm.ping()
                        } catch (e) {
                            if (e && e.status === 401) {
                                return vm.handleLogout()
                            }

                            if (vm.$sentry) {
                                vm.$sentry.withScope(scope => {
                                    scope.setExtra('context', e)
                                    vm.$sentry.captureMessage('Error in ping request')
                                })
                            }
                        }
                    }, 30000)
                }
            }
        },
        created () {
            if (!contains(['/login', '/auth-check'], this.$route.path)) {
                this.$router.push({path: `/login${window.location.search}`})
            }
        }
    }
</script>
