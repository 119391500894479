export const LOGS = 'logs'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const PASSWORD = 'password'
export const MULTI_FACTOR_AUTH = 'two-factor-auth'

export default {
    LOGS,
    LOGIN,
    LOGOUT,
    PASSWORD,
    MULTI_FACTOR_AUTH
}
