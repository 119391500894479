<template>
    <!-- BaseAccountMenu.vue -->
    <base-account-menu :email-address="emailAddress">
        <v-list-item v-for="item in items" :key="item.title" v-bind="item.attrs" v-on="item.on">
            <v-list-item-avatar>
                <v-icon :color="item.attrs.color">{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </base-account-menu>
</template>

<script>
    // Components
    import BaseAccountMenu from '@framework/components/BaseAccountMenu'

    export default {
        name: 'AccountMenu',
        extends: BaseAccountMenu,
        components: {
            BaseAccountMenu
        },
        props: {
            /**
             * The url for the help and support page on the windsor site
             */
            helpUrl: {
                type: String,
                required: true
            },
            /**
             * The url for the help and support page on the windsor site
             */
            statusPageUrl: {
                type: String,
                required: true
            }
        },
        computed: {
            items () {
                const vm = this

                return [
                    {
                        attrs: {color: 'info', href: vm.helpUrl, target: '_blank'},
                        on: {},
                        icon: 'mdi-help-circle',
                        title: 'Help & Support'
                    },
                    {
                        attrs: {color: 'info', href: vm.statusPageUrl, target: '_blank'},
                        on: {},
                        icon: 'mdi-server',
                        title: 'Status Page'
                    },
                    {
                        attrs: {color: 'error'},
                        on: {click: vm.handleClickLogout},
                        icon: 'mdi-exit-to-app',
                        title: 'Logout'
                    }
                ]
            }
        },
        methods: {
            handleClickLogout () {
                /**
                 * @event click:logout
                 */
                this.$emit('click:logout')
            }
        }
    }
</script>
