import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import {contains} from 'underscore'
import qs from 'qs'

import * as routeNames from './route-names'

const Password = () => import(/* webpackChunkName: "my-account-password" */'../views/Password')
const MultiFactorAuth = () => import(/* webpackChunkName: "my-account-mfa" */'../views/MultiFactorAuth')
const Logs = () => import(/* webpackChunkName: "my-account-logs" */'../views/Logs')
const Login = () => import(/* webpackChunkName: "my-account-app-wrapper" */'../views/Login')
const Logout = () => import(/* webpackChunkName: "my-account-app-wrapper" */'../views/Logout')
const BaseNotFound = () => import(/* webpackChunkName: "my-account-app-wrapper" */'@framework/components/BaseNotFound')

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: {name: routeNames.PASSWORD}
        },
        {
            path: '/security',
            name: routeNames.PASSWORD,
            component: Password
        },
        {
            path: '/security/two-factor-auth',
            name: routeNames.MULTI_FACTOR_AUTH,
            component: MultiFactorAuth
        },
        {
            path: '/security/logs',
            name: routeNames.LOGS,
            component: Logs
        },
        {
            path: '/login',
            redirect: '/auth-check'
        },
        {
            path: '/auth-check',
            name: routeNames.LOGIN,
            component: Login
        },
        {
            path: '/logout',
            name: routeNames.LOGOUT,
            component: Logout
        },
        {
            path: '*',
            component: BaseNotFound,
            props: {
                redirect: {path: '/'}
            }
        }
    ],
    parseQuery (query) {
        return qs.parse(query)
    },
    stringifyQuery (query) {
        const result = qs.stringify(query, {arrayFormat: 'brackets'})
        return result ? `?${result}` : ''
    }
})

router.beforeEach(async (to, from, next) => {
    const nextRoute = store.getters['auth/nextRoute']
    const params = new URLSearchParams(window.location.search)
    let url = params.get('redirectUrl')

    if (url) {
        localStorage.setItem('redirectUrl', url)
    } else {
        localStorage.removeItem('redirectUrl')
    }
    if ((to.path !== nextRoute.path) && !contains(['/login', '/auth-check'], to.path)) {
        store.dispatch('auth/setNextRoute', to)
        store.dispatch('auth/setFromRoute', from)
    }

    if (to.fullPath === from.fullPath && from.path !== '/') {
        return next(false)
    }

    next()
})

export default router
