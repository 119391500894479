import {isEmpty} from 'underscore'
import * as mutationTypes from './mutation-types'
import api from '@/store/api'

const namespaced = true

const initialState = () => ({
    auth: {
        email_address: null,
        user_id: null,
        mfa_data: null,
        password_requirements: {}
    },
    loading: false,
    nextRoute: {},
    fromRoute: {}
})

const state = initialState()

const getters = {
    auth: state => state.auth,
    emailAddress: state => state.auth.email_address,
    nextRoute: state => state.nextRoute,
    fromRoute: state => state.fromRoute,
    isAuthed: state => Boolean(state.auth?.user_id),
    mfaData: state => state.auth.mfa_data,
    hasMfa: state => !isEmpty(state.auth.mfa_data),
    hasSmsFallback: (state, getters) => getters.hasMfa && state.auth.mfa_data.sms_fallback,
    passwordRequirements: state => state.auth.password_requirements
}

const actions = {
    login: async ({commit, dispatch}, data) => {
        try {
            const response = await api.auth.login(data)
            commit(mutationTypes.SET_AUTH, response)
            return response
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    me: async ({commit, dispatch}, token) => {
        try {
            const response = await api.auth.me(token)
            commit(mutationTypes.SET_AUTH, response)
            return response
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    logout: async ({commit, dispatch}) => {
        try {
            const response = await api.auth.logout()
            commit(mutationTypes.RESET_AUTH)
            return response
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    ping: async ({dispatch}) => {
        try {
            return await api.auth.ping()
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    /**
     * @param {{password: string, confirm_password: string}} data
     * @returns {Promise<*>}
     */
    changePassword: async ({dispatch}, data) => {
        try {
            return await api.auth.changePassword(data)
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    resetAuth: async ({commit}) => commit(mutationTypes.RESET_AUTH),
    setAuth: async ({commit}, data) => commit(mutationTypes.SET_AUTH, data),
    setLoading: async ({commit}, data) => commit(mutationTypes.SET_LOADING, data),
    setNextRoute: async ({commit}, data) => commit(mutationTypes.SET_NEXT_ROUTE, data),
    setFromRoute: async ({commit}, data) => commit(mutationTypes.SET_FROM_ROUTE, data)
}

const mutations = {
    [mutationTypes.RESET_AUTH] (state) {
        Object.assign(state, initialState())
    },
    [mutationTypes.SET_AUTH] (state, data) {
        const {mfa_data: mfaData, password_requirements: passwordRequirements} = data.data

        state.auth.email_address = data.email_address
        state.auth.user_id = data.user_id
        state.auth.mfa_data = mfaData
        state.auth.password_requirements = {
            minLength: passwordRequirements.min_length,
            minNumbers: passwordRequirements.min_numbers,
            minSymbols: passwordRequirements.min_symbols,
            minLowercase: passwordRequirements.min_lowercase,
            minUppercase: passwordRequirements.min_uppercase
        }
    },
    [mutationTypes.SET_LOADING] (state, data) {
        state.loading = data
    },
    [mutationTypes.SET_NEXT_ROUTE] (state, data) {
        state.nextRoute = data
    },
    [mutationTypes.SET_FROM_ROUTE] (state, data) {
        state.fromRoute = data
    }
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
